var products_array = [
    "backpack", "hoodie", "unisex-sweatshirt", "tote-bag",
    "jigsaw-puzzle-520", "canvas-1824", "crop-tee-s", "gym-bag",
    "beanie", "premium-pillow", "joggers-women", "gaming-mouse-pad",
    "tank-top-women", "tough-iphone-case", "men-atheletic-t-shirt", "minimalist-backpack",
    "women-crew-neck-t-shirt", "duffle-bag", "men-windbreaker", "bomber-jacket",
    "one-piece-swimsuit", "unisex-track-pants", "unisex-wide-leg-pants", "long-sleeve-midi-dress", 
    "women-cropped-windbreaker", "headband", "youth-leggings", "tank-top-men", 
    "swim-trunks", "yoga-leggings", "sports-bra", "yoga-mat", 
    "mens-athletic-shoes", "womens-athletic-shoes", "women-t-shirt-dress", "mens-crew-neck-t-shirt", 
    "kids-swimsuit", "kids-crew-neck-t-shirt", "women-atheletic-t-shirt", "skater-dress",
    "men-high-top", "men-slide", "skater-skirt", "women-slide", "women-high-top",
];

const tooltips_array = [
    "Backpack", "Hoodie", "Sweatshirt", "Tote Bag",
    "Puzzle", "Canvas", "Crop Tee", "Gym Bag",
    "Beanie", "Pillow", "Women's Joggers", "Mousepad",
    "Tanktop", "iPhone Case", "Athletic Tee", "Mini Backpack",
    "Women's T-Shirt", "Duffle Bag", "Men's Windbreaker", "Bomber Jacket",
    "Swimsuit", "Track Pants", "Wide Leg Pants", "Midi Dress",
    "Women's Windbreaker", "Head Band", "Youth Leggings", "Men's Tank Top",
    "Swim Trunks", "Yoga Leggings", "Sports Bra", "Yoga Mat",
    "Men's Shoes", "Women's Shoes", "Women's T-shirt Dress", "Men's T-Shirt",
    "Female Kids Swimsuit", "Kids Crew Neck T-Shirt", "Women's Athletic Tee", "Women's Skater Dress",
    "Men High Top", "Men Slide", "Skater Skirt", "Women Slide", "Women High Top",
];

export {products_array, tooltips_array}