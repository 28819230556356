import Select from "react-select";
import React, { useEffect, useState } from "react";
import { withPrefix } from "gatsby";
import { createTask, getPrintfulTask } from "../services/apiCalls";

function waitFiveSeconds() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 5000);
  });
}

const getPrintfulData = async(task_key) => {
  let completed = false;
  let count = 0;
  let printfulRes;

  while (!completed && count < 10) {
    await waitFiveSeconds()
    printfulRes = await getPrintfulTask(task_key, 'no');
    if (printfulRes.result?.status === "completed") {
      completed = true;
    } else {
      count++;
    }
  }

  return printfulRes?.result?.mockups;
}

export default function Layout({
  CustomClass,
  ProductName,
  productKey,
  options,
  onDDChange,
}) {
  const [product, setProduct] = useState(null);

  const getImage = () => {
    return product?.[0]?.value?.[0] ? `${product[0].value[0]}?version=200` : withPrefix("assets/img/green-little-balls.gif");
  }

  const [generatingSku, setGeneratingSku] = useState(true);
  const handleGenerateClick = async () => {
    setGeneratingSku(true);

    const allItems = JSON.parse(localStorage.getItem("allItems") || "[]");
    const item = allItems.find((item) => item.key === productKey);
    const upscaled = item.upscaled;
    const logo = item.logo;
    const name = productKey.split('_')[1];

    try {
      const taskRes = await createTask({
        image: upscaled,
        logo: logo ?? undefined,
        name,
      });

      if (taskRes.status == "success") {
        const { result } = taskRes.data.que;
        const mockups = await getPrintfulData(result.task_key);

        if (mockups?.length) {
          const images = [];
          const placement = [];
          
          mockups.forEach((mockup) => {
            images.push(mockup.mockup_url);
            placement.push(mockup.placement);
          });

          setProduct([{
            placement, value: images
          }]);
        }
      }      
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratingSku(false);
    }
  }

  //For the first time
  useEffect(() => {
    if (!product) {
      handleGenerateClick();
    }
  }, [product])

  return (
    <div className="col-md-2" alt={ProductName}>
      <div className={"box"} alt={ProductName}>
        <div className={CustomClass}>
          {product ? (
            <img
              className="merchImages"
              id="merchImages"
              src={getImage()}
              alt={ProductName}
            />
          ) : generatingSku ? (
            <img
              className="merchImages"
              id="merchImages"
              src={withPrefix("assets/img/green-little-balls.gif")}
              alt={ProductName}
            />
          ) : (
            <div className="refresh-btn-container" style={{ display: 'inline-block' }}>
              <div className="txt-max" style={{ margin: '1rem 0' }}>
                <div className="icon-info">
                  <img src={withPrefix("assets/img/icon-info-lrg.png")} alt="icon-info-lrg" />
                </div>
                <p style={{ margin: '1rem' }}>something went wrong, <br /> please try again.</p>
                <button 
                  className="btn btn-blue btn-refresh" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGenerateClick();
                  }}
                >Refresh</button>
              </div>
            </div>
          )}
        </div>
        <Select
          isSearchable={false}
          name={CustomClass}
          className={"select-input "}
          placeholder={"Select Size"}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 2, fontSize: 12 }),
          }}
          menuPortalTarget={document.querySelector("body")}
          onChange={(selectedOption) => onDDChange(selectedOption, productKey)}
          options={options}
          classNamePrefix="react-select"
        />
      </div>
    </div>
  );
}
