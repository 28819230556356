import React, { useState } from "react";
import { withPrefix } from "gatsby";
import { useEffect } from "react";
import { navigate } from "gatsby";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { useApplicationContext } from "../../provider";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { createPrintfulProduct, selectCheckoutState } from "../sagas/checkout/checkoutSlice";
import ProductSlot from "../components/product-cart";
import { tooltips_array } from "../services/mock";

export default function Layout() {
  const dispatch = useDispatch();
  const { isCreatingPrintfulProduct, isCreatingPrintfulProductSuccess, isCreatingPrintfulProductFailed, CreatePrintfulProductData } = useSelector(selectCheckoutState);

  const { checkoutState, setCheckoutState } = useApplicationContext();
  const [selectedVariants, setSelectedVariants] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [dropdownOption, setDropdownOption] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedArray, setSelectedArray] = React.useState([]);
  useEffect(() => {
    localStorage.removeItem('final-products');
    getProductsList();
    setSelectedVariants([]);

    const selectedArray = typeof window !== "undefined" && JSON.parse(localStorage.getItem("selectedArray") || "[]");
    setSelectedArray(selectedArray ?? []);
  }, []);

  useEffect(() => {
    setDDOptions();
  }, [data]);

  function pushOrUpdateArray(array, obj, currentArray, type) {
    removeCommonObjects(currentArray, array);
    const index = array.findIndex((item) => item.id === obj.id);
    if (index === -1) {
      array.push(obj);
    } else {
      array.splice(index, 1, obj);
    }
    return array;
  }

  const onProceed = async(e) => {
    e.preventDefault();
    localStorage.setItem("final-products", JSON.stringify(selectedVariants) );

    if (selectedVariants.length > 0) {
      navigate("/shipping-address");
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select a size to continue!',
      })
    }
  };

  function removeCommonObjects(array1, array2) {
    for (let i = 0; i < array1.length; i++) {
      const index = array2.indexOf(array1[i]);
      if (index !== -1) {
        array2.splice(index, 1);
      }
    }
    return array2;
  }

  const onDDChange = (selectedOption, obj) => {
    data.find((item) => {
      item.sync_variants.map((val) => {
        let currentArray = item.sync_variants;
        if (`${item.unique_type}_${val.variant_id}` == selectedOption.value) {
          const newOne = pushOrUpdateArray(selectedVariants, val, currentArray);
          console.log(newOne)
          setSelectedVariants(newOne);
        }
      });
    });
  };

  const getSize = (variant) => {
    if (!variant.color) return variant.size;
    return `${variant.color} Strap / ${variant.size}`;
  }

  const setDDOptions = () => {
    let optionArray = [];
    data && data.map((item) => {
      const dropdownOptions = item.sync_variants.map((val) => {
        return { label: getSize(val), value: `${item.unique_type}_${val.variant_id}` };
      });
      optionArray.push(dropdownOptions);
    });

    setDropdownOption(optionArray);
  };

  const getProductsList = async () => {
    const allItems = JSON.parse(localStorage.getItem("allItems") || "[]");
    const selectedArray = JSON.parse(localStorage.getItem("selectedArray") || '[]');

    const payload = selectedArray.map((key) => {
      const item = allItems.find((item) => item.key === key);
      const carousel_uID = key.split('_')[0];
      const type = key.replace(`${carousel_uID}_`, '');

      return {
        image: item.thumbnail,
        type,
        sku_name: carousel_uID,
        logo: item.logo,
      }
    });

    if (!checkoutState.newJourney && checkoutState.createPrintfulProductData.length) {
      setData(checkoutState.createPrintfulProductData);
    } else {
      dispatch({
        type: createPrintfulProduct.type,
        payload,
      });
    }
  };

  useEffect(() => {
    if (isCreatingPrintfulProduct) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isCreatingPrintfulProduct])

  useEffect(() => {
    if (isCreatingPrintfulProductSuccess && CreatePrintfulProductData) {
      const allItems = JSON.parse(localStorage.getItem("allItems") || "[]");
      
      const data = CreatePrintfulProductData.map((item) => {
        const selectedItem = allItems.find((carouselItem) => carouselItem.key === item.unique_type);

        return {
          ...item,
          sync_variants: item.sync_variants.map((variant) => {
            return {
              ...variant,
              unique_type: item.unique_type,
              productImage: selectedItem?.value?.images?.[0],
            }
          })
        }
      })
      setData(data);
      setCheckoutState({ ...checkoutState, createPrintfulProductData: data, newJourney: false });

    } else if (isCreatingPrintfulProductFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch order details',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) getProductsList();
      })
    }
  }, [isCreatingPrintfulProductSuccess, isCreatingPrintfulProductFailed, CreatePrintfulProductData])

  const getBreadCrumbs = () => {
    switch (checkoutState.flow) {
      case "create":
        return (
          <div className="breadcrumb">
            <ul>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/beta"); }}>Create</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/choose-art"); }}>Choose Art</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/select-merch"); }}>Choose Merch</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Shop/Sell</a></li>
            </ul>
          </div>
        );

      case "upload":
        return (
          <div className="breadcrumb">
            <ul>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/beta"); }}>Upload</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/select-merch"); }}>Choose Merch</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Shop/Sell</a></li>
            </ul>
          </div>
        );

      case "my-creations":
        return (
          <div className="breadcrumb">
            <ul>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/my-creations"); }}>My Creations</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/my-creations-view"); }}>Choose Merch</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Shop/Sell</a></li>
            </ul>
          </div>
        );

      default:
        return (
          <div className="breadcrumb">
            <ul>
              <li><a href="#" onClick={(e) => { window.location.href = `${window.location.origin}/search`; }}>Search</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/select-merch"); }}>Choose Merch</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Shop/Sell</a></li>
            </ul>
          </div>
        );
    }
  }

  return (
    <>
      {loading && (
        <div
          className="new_gif"
          style={{ display: "flex", top: 0, left: 0 }}
        >
          <img
            src={withPrefix("assets/img/green-little-balls.gif")}
            className="img-fluid mw_50"
            alt="loading"
          />
        </div>        
      )}

      <div className="container-main" id="page">
        <Header></Header>
        <main className="content-main">
          {getBreadCrumbs()}
     
          <div className="select-feature-list">
            <div className="container">
              <div className="select-merch">
                <div className="row-container">
                  <div className="container-inn" id="page-header">
                    <div className="heading-top">
                      <h2>Cart Items List</h2>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="container-inn">
                    <div className="row" id="product-carousel-slot">
                      {selectedArray.map((key, i) => (
                        <ProductSlot
                          onDDChange={onDDChange}
                          options={dropdownOption[i]}
                          productKey={key}
                          CustomClass={key + " figure"}
                          key={i}
                          ProductName={tooltips_array[i]}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row-container">
                  <div className="bottom-btns">
                    <button
                      onClick={(e) => onProceed(e)}
                      className="btn btn-blue"
                    >
                      PROCEED
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
